.componentSelectorTitle {
  text-align: left;
  padding-bottom: 16px;
}

.renderBreadCrumsDiv {
  display: flex;
  align-items: center;
  height: 15px;
}

.renderBreadCrumsDivElse {
  display: flex;
  align-items: center;
}

.renderBreadCrumsPtag {
  cursor: pointer;
  max-width: 20vw;
  margin: 0;
  overflow-x: hidden;
  white-space: nowrap;
}
.form-toolbar {
  min-height: 56px !important;
}

.gridStyle {
  top: 100px;
  position: absolute;
}

.saveSessionTitle {
  color: #000000;
  font-size: 18px;
  font-weight: normal;
}

.saveSessionGreenTick {
  margin-right: 8px;
  color: #43a047;
}

.saveSessionImageSize {
  height: 264px;
  width: auto;
  margin: auto;
  margin-top: 8px;
}

.saveSessionDialogContentText {
  width: 100%;
  height: 48px;
  left: 8px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.descriptionDiv {
  width: 100%;
  margin: auto;
}

.descriptionTitle {
  margin-top: 16px;
  text-align: left;
}

.descriptionImageDiv {
  margin-top: 24px;
  padding: 8px;
  border-radius: 6px;
  background-color: rgba(176, 0, 32, 0.1);
  display: flex;
  align-items: center;
}

.descriptionImage {
  margin-right: 16px;
  color: #b00020;
}

.descriptionQuestionDiv {
  margin-top: 24px;
  margin-bottom: 8px;
  position: relative;
  z-index: 0;
  text-align: left;
  overflow-wrap: break-word;
  font-weight: 600;
}

.raw-text-style > .bf-content > div {
  pointer-events: none;
}

.bf-container > .bf-controlbar {
  display: none;
}

.raw-text-style > .bf-content {
  padding-bottom: 0px;
}

.descriptionQuestionPtag {
  text-align: left;
  margin: auto;
  color: #868686;
}

.descriptionAnswerDiv {  
  text-align: left;
  margin-bottom: 32px;
}

.conditionText {
  margin-left: 4px;
  font-size: 11px !important;
}

.divForm {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 24px;
  width: 800px;
  height: min-content;
  background: #ffffff;
  display: inline-table;
}

.m-0 {
  margin: 0 !important;
}
.p-0 {
  padding: 0 !important;
}

/* div.bf-container.read-only{
  padding-top:0px;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr{
  height:0px;
} */
.descriptionAnswerPtag {
  text-align: left;
  margin: auto;
  color: #868686;
}

.s-text {
  color: #868686;
  margin: 8px;
}

.autocomplete {
  width: 100%;
}

.buttonProgress {
  color: #2196f3;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.save-btn {
  width: 100%;
}

.page-title {
  font-size: 26px !important;
  font-weight: 500 !important;
  text-align: left;
  margin-top: 24px;
}
.account-container > button {
  padding-left: 0px !important;
}

.account-container > button > span {
  width: min-content;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.account-container button:focus {
  outline: unset !important;
}

.text-field-form {
  height: 34px;
  width: 100%;
  padding: 0 16px;
}

.imageStyle {
  height: 356px;
  width: 464px;
  margin-bottom: 32px;
  margin-top: 140px;
}

.formControl {
  width: 420px;
  margin: 0;
}

.err-text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: #b00000;
  margin: 22px 0;
}

.iconDiscard {
  display: inline-block;
  float: left;
  margin-right: 12px;
  color: #b00020;
}

.form-cover-image{
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .save-btn {
    width: 100%;
    position: absolute;
    margin: 8px 0 0 -24px;
    text-align: left;
    background: #fff;
    padding: 8px;
    min-width: 200px;
    top: -15px;
    left: 100px;
    display: flex;
  }

  .divForm {
    height: auto;
    margin-top: 32px;
    width: auto;
  }

  .account-container > button {
    padding: 8px 0;
  }

  .menu-btn {
    margin-top: 8px;
    padding-left: 0px;
  }
}

.form-footer {
  font-size: 12px;
  justify-content: flex-start;
  width: 100%;
  line-height: 16px;
  color: #868686;
  display: flex;
}

@media only screen and (max-width: 575px) {
  .saveSessionImageSize {
    width: 100%;
  }
  .save-btn {
    width: 100%;
    position: absolute;
    margin-top: 8px;
    text-align: left;
    top: -5px;
    left: 100px;
    display: flex;
  }
  .menu-btn {
    margin-top: 8px;
    padding-left: 0px;
  }

  .divForm {
    height: auto;
    margin-top: 32px;
    width: 300px;
  }

  .imageForm {
    width: 100%;
  }

  .imageStyle {
    width: 100%;
  }

  .formControl {
    width: auto;
  }

  .form-footer {
    display: unset;
  }
}
.divRender {
  display: flex;
  color: #868686;
  margin: 16px 0;
  height: 15px !important;
}
.copy-text.MuiOutlinedInput-input {
  align-self: flex-start !important;
  padding: 0px !important;
  margin: 16px;
}
.start-alignment {
  align-items: flex-start !important;
  text-align: start !important;
  margin-top: 8px;
}
.copy-btn {
  margin-left: 16px !important;
}
.copy-textfield-container {
  margin-top: 8px !important;
}
.stripDecoration {
  text-decoration: none;
}
.autosaveGrid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.highlightme-annotation { 
  line-height: initial;
}
.highlightme-annotation:hover {
  cursor: pointer;
  filter: brightness(1.2);
  user-select: none;
}
.save-form-render-item-btn {
  border: 1px solid #2196f3 !important;
}

.save-form-render-item-btn-red {
  border: 1px solid #E10050 !important;
}

.form-title {
  font-size: 24px;
  line-height: 1.2em;
}

.form-title-container {
  margin-left: 8px;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

.form-logo-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.app-bar {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0,0,0,10%);
}

.form-logo-wrapper {
  width: auto;
  height: 32px;
}

.form-logo {
  width: auto;
  height: 100%;
  border-radius: 8px;
}

.menu-icon-button {
  padding-right: 16px;
}

.menu-item-current {
  color: #2196f3;
}

.circular-progress {
  color: #2196f3;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.annotation-mode-active {
  border: 6px solid #2196F3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9999;
}

.annotation-mode-banner {
  display: inline-flex;
  align-items: center;
  background-color: #2196F3;
  border-radius: 0 0 12px 12px;
  padding: 6px 24px;
  margin: 0px;
  color: #FFF;
  white-space: normal;
  gap: 4px;
  text-decoration: none;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  transition: box-shadow 0.3s ease;
  z-index: 9999;
  max-width: 40%;
  text-align: center;
  font-size: 0.9em;
  font-weight: 600;
}

.annotation-mode-banner:hover {
  box-shadow: 0 6px 20px rgba(0,0,0,0.3);
}

.annotation-mode-banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent);
  transition: all 650ms;
}

.annotation-mode-banner:hover:before {
  left: 100%;
}

.annotation-mode-active.readonly {
  border-color: #484848;
}

.annotation-mode-banner.readonly {
  background-color: #484848;
  border-color: #484848;
  border-bottom: 1px solid #484848;
}

.annotation-mode-active.terminated {
  border-color: #B00020;
}

.annotation-mode-banner.terminated {
  background-color: #B00020;
  border-color: #B00020;
  border-bottom: 1px solid #B00020;
}