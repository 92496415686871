.main-page {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0px !important;
}

.main-form-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: white;
    background-image: radial-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 0);
    background-size: 40px 40px;
    background-position: -19px -19px;
    position: relative;
    overflow: hidden;
}

.main-form-login h4 {
    margin: 16px 0;
}

.mainDiv {
    padding: 40px;
    width: 100%;
    max-width: 400px;
    background-color: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
    margin: 0;
}

.title {
    padding-top: 8px;
    height: 32px;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin: 0px 8px;
}

.divImageStyle {
    height: 256px;
}

.imageSize {
    height: auto;
    width: auto;
    max-height: 256px;
    margin: auto;
    display: block;
    object-fit: contain;
}

.inputDescription {
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #868686;
    margin: 8px 0px;
}

.inputSize {
    width: 100%;
}

.actionArea a {
    display: block;
    cursor: pointer;
    padding-top: 8px;
}

.forgotPassword {
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    color: #2196F3;
    letter-spacing: 0.15px;
}

.sign-in-with-gg {
    width: 100%;
    margin-bottom: 16px !important;
    background: #DD4B39 !important;
    color: #ffffff !important;
}

.sign-in-with-gg:disabled {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
    background-color: #f2f2f2 !important;
}

.logo-image {
    max-width: 50%;
    height: auto;
}

.signInButton {
    width: 100% !important;
    background-color: black !important;
    color: white !important;
    padding: 10px 20px !important;
    font-size: 14px !important;
    margin-top: 24px !important;
}

.signInButton:disabled {
    background-color: #E0E0E0 !important;
    color: #9E9E9E !important;
    cursor: not-allowed !important;
    box-shadow: none !important;
    opacity: 0.7 !important;
    border: none !important;
}

/* If still not working, try targeting MUI's internal classes */
.signInButton.Mui-disabled {
    background-color: #E0E0E0 !important;
    color: #9E9E9E !important;
}

/* For the Google button */
.sign-in-with-gg:disabled,
.sign-in-with-gg[disabled] {
    background-color: #E0E0E0 !important;
    color: #9E9E9E !important;
    opacity: 0.7 !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
}

/* This universal selector handles all text alignment */
.mainDiv * {
    text-align: left;
}

/* If you need to target specific buttons, you can also use their classes */
.signInButton,
.sign-in-with-gg,
.backButton,
.resetButton {
  text-transform: none !important;
}

.input-field {
  width: 100%;
}

.buttonProgress {
  margin-left: 10px;
}