.navigation-sticky {
    position: sticky;
    top: 0px;
}

.navigation-loader {
    position: sticky;
    top: 110px;
}

.dialog-title {
    text-align: start;
}

.dialog-text {
    text-align: start;
}

.button-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}

.navigation-accordion {
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    box-shadow: "0px 2px 4px 0px rgba(16, 24, 40, 0.06)",
}