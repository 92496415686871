.user-account-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
}

.upload-instructions {
  margin-top: 8px;
  text-align: left;
  line-height: 24px;
}

.column-instructions {
  margin: 0;
  color: #868686;
  margin-bottom: 24px;
  text-align: left;
}

.file-chip-wrapper {
  padding-top: 8px;
  float: left;
  display: flex;
}

.file-chip {
  background-color: rgba(33, 150, 243, 0.6);
}

.file-chip-label {
  margin: 8px;
}

.error-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.submit-instructions {
  margin: 40px 0 8px 0;
}

.buttonProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.button-wrapper {
  text-align: left;
  margin-bottom: 16px;
}

.button-wrapper button {
  margin-bottom: 16px;
  border: 1px solid black;
  background-color: white;
  color: black;
}

.button-wrapper button:first-child {
  margin-right: 16px;
}

.template-button {
  margin-bottom: 16px;
  border: 1px solid black;
  background-color: white;
  color: black;
}

.csv-button {
  margin-right: 16px;
}

.missing-columns {
  margin-top: 8px;
}

.bulk-assessment-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 50px); /* Adjust this value based on the width of the UserAccount component */
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}